import Debugger from "@scripts/core/Debugger.js";
import { forEach } from "@splidejs/splide/src/js/utils";
import ViewportManager from "@scripts/core/ViewportManager.js";

export default class ResponsivePagination {
  constructor(selector) {
    this.selector = selector;
    this.container = null;
    this.elements = {
      static: new Map(),
      button: new Map(),
    };

    this.renderedElements = 0;
    this.possibleElements = 0;
    this.currentButtonIndex = 0;
    this.offset = 3;
    this.separators = 0;
    this.maxElements = 10;

    this.getElements();
    this.setConfig();
    this.handleEvents();
    this.build(
      this.renderedElements,
      this.possibleElements,
      this.currentButtonIndex,
    );
  }

  resetElements() {
    this.elements = {
      static: new Map(),
      button: new Map(),
    };
  }

  getElements() {
    this.container =
      typeof this.selector === "string"
        ? document.querySelector(this.selector)
        : this.selector;

    if (!this.container) {
      console.error("Pagination container not found");
      return;
    }

    let staticEl = this.container.querySelectorAll(
      '[data-responsive-pagination="static"]',
    );
    let buttonsElements = this.container.querySelectorAll(
      "[data-responsive-pagination-button]",
    );

    staticEl.forEach((item, index) => {
      this.elements.static.set(index, item);
    });

    buttonsElements.forEach((item, index) => {
      let elementData = {
        element: item,
        index: index,
        current: item.dataset.responsivePaginationButton === "current",
      };

      if (item.dataset.responsivePaginationButton === "current") {
        this.currentButtonIndex = index;
      }

      this.elements.button.set(index, elementData);
    });
  }

  handleEvents() {
    window.addEventListener("resize", () => this.debouncedUpdatePagination());
    document.addEventListener("livewire:init", () => {
      Livewire.on("initBlogComponent", () => this.debouncedUpdatePagination());
    });

    // hide container on button click
    this.elements.button.forEach((element, index) => {
      element.element.addEventListener("click", () => {
        this.hideContainer();
      });
    });
  }

  getRenderedElements() {
    this.renderedElements = 0;
    this.elements.static.forEach((element) => {
      this.renderedElements++;
    });

    this.elements.button.forEach((element) => {
      this.renderedElements++;
    });
  }

  calculatePossibleElementsInView() {
    let gap = ViewportManager.isMobile() ? 6 : 12;
    let containerWidth = this.container.offsetWidth;
    let elementsCount = 0;
    let staticElementsWidth = 0;
    let buttonElementsWidth = 0;
    let singleButtonSizeWithGap = 0;

    this.elements.static.forEach((element, index) => {
      staticElementsWidth += element.offsetWidth;
      if (index !== this.elements.static.size - 1) {
        Debugger.debug(
          "element.index !== this.elements.button.size - 1",
          [element.offsetWidth],
          "info",
          "color: red",
        );
        staticElementsWidth += gap;
      }
      elementsCount++;
    });

    this.elements.button.forEach((element) => {
      buttonElementsWidth += element.element.offsetWidth;
      // add gap only if element is not the last one
      buttonElementsWidth += gap;
      elementsCount++;
    });

    singleButtonSizeWithGap =
      this.elements.button.get(0)?.element?.offsetWidth + gap;

    let totalElementsWidth = staticElementsWidth + buttonElementsWidth;

    Debugger.debug(
      "data",
      [
        totalElementsWidth,
        staticElementsWidth,
        buttonElementsWidth,
        containerWidth,
      ],
      "info",
      "color: red",
    );

    if (totalElementsWidth > containerWidth) {
      let spaceWithoutStatic = containerWidth - staticElementsWidth;

      let buttonsThatCanFit = Math.floor(
        spaceWithoutStatic / singleButtonSizeWithGap,
      );

      Debugger.debug(
        "spaceWithoutStatic",
        spaceWithoutStatic,
        "info",
        "color: red",
      );
      Debugger.debug(
        "buttonsThatCanFit",
        buttonsThatCanFit,
        "info",
        "color: red",
      );

      if (buttonsThatCanFit > 0) {
        return buttonsThatCanFit;
      } else {
        return 0;
      }
    } else {
      return elementsCount;
    }
  }

  debouncedUpdatePagination() {
    clearTimeout(this.updatePaginationTimeout);
    this.updatePaginationTimeout = setTimeout(
      () => this.updatePagination(),
      100,
    );
  }

  hideContainer() {
    this.container.style.opacity = 0.8;
  }

  showContainer() {
    this.container.style.opacity = 1;
  }

  updatePagination() {
    this.hideContainer();
    this.removeAllSeparators();
    this.resetElements();
    this.getElements();
    this.setConfig();
    this.build(
      this.renderedElements,
      this.possibleElements,
      this.currentButtonIndex,
    );
  }

  setConfig() {
    Debugger.debug("setConfig", this.elements, "info", "color: red");
    this.getRenderedElements();
    this.possibleElements = this.calculatePossibleElementsInView();
  }

  build(renderedElements, possibleElements, currentButtonIndex) {
    Debugger.debug(
      "build0",
      [renderedElements, possibleElements, currentButtonIndex],
      "info",
      "color: red",
    );

    possibleElements = Math.min(possibleElements, this.maxElements);
    let posibbleElementsWithouthCurrent = possibleElements - 1;

    Debugger.debug(
      "build",
      [
        renderedElements,
        possibleElements,
        currentButtonIndex,
        posibbleElementsWithouthCurrent,
      ],
      "info",
      "color: red",
    );
    if (renderedElements > possibleElements) {
      this.hideAllButtons();
      let requiredelements = this.showRequiredButtons(currentButtonIndex);
      posibbleElementsWithouthCurrent =
        posibbleElementsWithouthCurrent - requiredelements;
      Debugger.debug(
        "build",
        [
          renderedElements,
          possibleElements,
          currentButtonIndex,
          posibbleElementsWithouthCurrent,
        ],
        "info",
        "color: gold",
      );

      if (posibbleElementsWithouthCurrent > 0) {
        this.showSiblingsButtons(
          currentButtonIndex,
          posibbleElementsWithouthCurrent,
          this.offset,
        );
      }
    }

    this.showContainer();
  }

  hideAllButtons() {
    this.elements.button.forEach((element) => {
      if (element.index === this.currentButtonIndex) {
        return;
      }
      if (!element.current) {
        element.element.style.display = "none";
      }
    });
  }

  showSiblingsButtons(
    currentButtonIndex,
    posibbleElementsWithouthCurrent,
    offset,
  ) {
    let showed = 0;
    let lastShowedPrevIndex = currentButtonIndex - 1;
    let lastPrevJumpOffset = 0;
    let lastShowedNextIndex = currentButtonIndex + 1;
    let lastNextJumpOffset = 0;

    Debugger.debug(
      "showSiblingsButtons",
      [currentButtonIndex, posibbleElementsWithouthCurrent, offset],
      "info",
      "color: red",
    );

    while (showed < posibbleElementsWithouthCurrent) {
      if (lastShowedPrevIndex >= 0) {
        this.elements.button.get(lastShowedPrevIndex).element.style.display =
          "block";
        lastShowedPrevIndex--;
        lastPrevJumpOffset++;
        showed++;
      }

      if (showed >= posibbleElementsWithouthCurrent) {
        break;
      }

      if (lastShowedNextIndex < this.elements.button.size) {
        this.elements.button.get(lastShowedNextIndex).element.style.display =
          "block";
        lastShowedNextIndex++;
        lastNextJumpOffset++;
        showed++;
      }

      if (showed >= posibbleElementsWithouthCurrent) {
        break;
      }

      if (lastPrevJumpOffset >= offset && lastNextJumpOffset >= offset) {
        break;
      }
    }
  }

  showFirstButton() {
    this.elements.button.get(0).element.style.display = "block";
  }

  addSeparatorAfterIndex(index) {
    // create inactive button with data-responsive-pagination-button="current" in html dom and add this to the elements.button map
    let separator = document.createElement("button");
    separator.textContent = "...";
    separator.classList.add("pagination-separator");
    separator.dataset.responsivePaginationButton = "separator";
    this.elements.button
      .get(index)
      .element.insertAdjacentElement("afterend", separator);
  }

  addSeparatorBeforeIndex(index) {
    // create inactive button with data-responsive-pagination-button="current" in html dom and add this to the elements.button map
    let separator = document.createElement("button");
    separator.textContent = "...";
    separator.classList.add("pagination-separator");
    separator.dataset.responsivePaginationButton = "separator";
    this.elements.button
      .get(index)
      .element.insertAdjacentElement("beforebegin", separator);
  }

  removeAllSeparators() {
    this.elements.button.forEach((element, index) => {
      if (element.element.dataset.responsivePaginationButton === "separator") {
        element.element.remove();
      }
    });
  }

  showLastButton() {
    this.elements.button.get(
      this.elements.button.size - 1,
    ).element.style.display = "block";
  }

  showRequiredButtons(currentButtonIndex) {
    let createdItems = 0;

    if (currentButtonIndex > 1) {
      Debugger.debug(
        "currentButtonIndex > 1",
        [currentButtonIndex],
        "info",
        "color: red",
      );
      this.showFirstButton();
      this.addSeparatorAfterIndex(0);
      createdItems = createdItems + 2;
    }

    if (currentButtonIndex < this.elements.button.size - 1) {
      Debugger.debug(
        "currentButtonIndex < this.elements.button.size - 1",
        [currentButtonIndex],
        "info",
        "color: red",
      );
      this.showLastButton();
      this.addSeparatorBeforeIndex(this.elements.button.size - 1);
      createdItems = createdItems + 2;
    }

    return createdItems;
  }
}
