import DomManager from "@scripts/core/DomManager.js";
import Mediator from "@scripts/core/Mediator.js";

export default class CartEventManager {
  constructor(cartManager) {
    this.cartManager = cartManager;
    this.addToCartButtons = [];
    this.removeFromCartButtons = [];
    this.addToCartListeners = new Map();
    this.removeFromCartListeners = new Map();
  }

  init() {
    this.#getDomElements();
    this.#handleDomEvents();

    Mediator.subscribe("mini_cart_updated", () => {
      this.#updateDOMEvents();
    });
  }

  #getDomElements() {
    this.addToCartButtons = document.querySelectorAll(
      "[data-ajax-add-to-cart]",
    );
    this.removeFromCartButtons = document.querySelectorAll(
      "[data-remove-from-cart]",
    );
  }

  #handleDomEvents() {
    this.addToCartButtons.forEach((button) => {
      const addToCartListener = (e) => {
        e.preventDefault();
        this.cartManager.addToCart(button);
      };
      button.addEventListener("click", addToCartListener);
      this.addToCartListeners.set(button, addToCartListener);
    });

    this.removeFromCartButtons.forEach((button) => {
      const removeFromCartListener = (e) => {
        e.preventDefault();
        this.cartManager.removeFromCart(button);
      };
      button.addEventListener("click", removeFromCartListener);
      this.removeFromCartListeners.set(button, removeFromCartListener);
    });
  }

  #updateDOMEvents() {
    this.#removeDomEvents();
    this.#getDomElements();
    this.#handleDomEvents();
  }

  #removeDomEvents() {
    this.addToCartListeners.forEach((listener, button) => {
      button.removeEventListener("click", listener);
    });
    this.addToCartListeners.clear();

    this.removeFromCartListeners.forEach((listener, button) => {
      button.removeEventListener("click", listener);
    });
    this.removeFromCartListeners.clear();
  }
}
