import domReady from "@roots/sage/client/dom-ready";
import LazyLoader from "@scripts/core/LazyLoader.js";
import AppAjaxHandler from "@scripts/components/appAjaxHandler.js";
import ResponsivePagination from "@scripts/components/ResponsivePagination.js";
import StickyCart from "@scripts/components/sticky-cart/StickyCart.js";
import ShippingBar from "@scripts/components/sticky-cart/ShippingBar.js";
import MiniCartWidget from "@scripts/components/cart/MiniCartWidget.js";
import CartEventManager from "@scripts/components/cart/CartEventManager.js";
import CartManager from "@scripts/components/cart/CartManager.js";
import ParallaxAnimateElement from "@scripts/components/ParallaxAnimateElement";

const pagination = (selector) => {
  const allElements = document.querySelectorAll(selector);

  console.log(allElements);

  allElements.forEach((element) => {
    new ResponsivePagination(element);
  });
};

function initCartEventManager() {
  const cartManager = CartManager.getInstance();
  const cartEventManager = new CartEventManager(cartManager);
  cartEventManager.init();
}

function initMiniCartWidgets() {
  const miniCartWidgets = document.querySelectorAll(
    '[data-widget-mini-cart="root"]',
  );
  miniCartWidgets.forEach((widget) => {
    new MiniCartWidget(widget).init();
  });
}

function initStickyCart() {
  const stickyCart = new StickyCart();
  stickyCart.init();
}

function initShippingBars() {
  const shippingBarsDomElements = document.querySelectorAll(
    "[data-shipping-bar]",
  );
  if (shippingBarsDomElements) {
    shippingBarsDomElements.forEach((htmlDomElement) => {
      new ShippingBar(htmlDomElement);
    });
  }
}

function initAnimateSingleItems() {
  const items = document.querySelectorAll("[data-js-parallax-animate-element]");
  items?.forEach((item) => {
    new ParallaxAnimateElement(item).init();
  });
}

/**
 * Application entrypoint
 */
domReady(async () => {
  pagination('[data-responsive-pagination="root"]');
  const lazyLoader = new LazyLoader();
  lazyLoader.registerClass(AppAjaxHandler, "AppAjaxHandler");
  lazyLoader.registerMethod(initCartEventManager, "initCartEventManager");
  lazyLoader.registerMethod(initMiniCartWidgets, "initMiniCartWidgets");
  lazyLoader.registerMethod(initStickyCart, "initStickyCart");
  lazyLoader.registerMethod(initShippingBars, "initShippingBars");
  lazyLoader.registerMethod(initAnimateSingleItems, "initAnimateSingleItems");
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
