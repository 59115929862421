import Mediator from "@scripts/core/Mediator.js";
import Debugger from "@scripts/core/Debugger.js";

export default class CouponCodeForm {
  #htmlDOMElement;
  #cartManager;
  #couponCodeForm;
  #couponCodeInput;
  #removeCouponButtons;
  #errorsWrapperDomElement;

  constructor(element, cartManager) {
    this.#htmlDOMElement = element;
    this.#cartManager = cartManager;

    if (!this.#htmlDOMElement) {
      return;
    }

    this.getHTMLDOMElements();
    this.initEvents();
    this.subscribe();
  }

  subscribe() {
    Mediator.subscribe("ajax_sage_change_cart_coupon_error", (response) => {
      Debugger.debug("ajax_sage_change_cart_coupon_error", response);
      this.#handleErrors(response);
    });

    Mediator.subscribe("ajax_sage_change_cart_coupon_success", (response) => {
      Debugger.debug("ajax_sage_change_cart_coupon_success", response);
      this.#handleErrors(response);
    });
  }

  #handleErrors(response) {
    // NOTE: Other action reinit HTML elements, so we need to get them again
    this.getHTMLDOMElements();

    if (!this.#errorsWrapperDomElement) {
      return;
    }

    if (response.success === true) {
      this.#htmlDOMElement.classList.remove("has-errors");
      this.#errorsWrapperDomElement.innerHTML = "";
    }

    if (response.success === false && response?.data?.message) {
      this.#htmlDOMElement.classList.add("has-errors");
      let icon =
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">\n' +
        '  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0013 2.49996C4.68759 2.49996 2.0013 5.18625 2.0013 8.49996C2.0013 11.8137 4.68759 14.5 8.0013 14.5C11.315 14.5 14.0013 11.8137 14.0013 8.49996C14.0013 5.18625 11.315 2.49996 8.0013 2.49996ZM0.667969 8.49996C0.667969 4.44987 3.95121 1.16663 8.0013 1.16663C12.0514 1.16663 15.3346 4.44987 15.3346 8.49996C15.3346 12.55 12.0514 15.8333 8.0013 15.8333C3.95121 15.8333 0.667969 12.55 0.667969 8.49996Z" fill="#E02424"/>\n' +
        '  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.0013 7.83329C8.36949 7.83329 8.66797 8.13177 8.66797 8.49996V11.1666C8.66797 11.5348 8.36949 11.8333 8.0013 11.8333C7.63311 11.8333 7.33463 11.5348 7.33463 11.1666V8.49996C7.33463 8.13177 7.63311 7.83329 8.0013 7.83329Z" fill="#E02424"/>\n' +
        '  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.33463 5.83329C7.33463 5.4651 7.63311 5.16663 8.0013 5.16663H8.00797C8.37616 5.16663 8.67463 5.4651 8.67463 5.83329C8.67463 6.20148 8.37616 6.49996 8.00797 6.49996H8.0013C7.63311 6.49996 7.33463 6.20148 7.33463 5.83329Z" fill="#E02424"/>\n' +
        "</svg>";

      Debugger.debug("response.data.message", response.data.message);
      Debugger.debug(
        "this.#errorsWrapperDomElement",
        this.#errorsWrapperDomElement,
      );

      this.#errorsWrapperDomElement.innerHTML = response.data.message;
    }
  }

  getHTMLDOMElements() {
    this.#couponCodeForm = this.#htmlDOMElement.querySelector(
      "[data-coupon-code-form]",
    );

    this.#couponCodeInput = this.#htmlDOMElement.querySelector(
      "[data-coupon-code-input]",
    );

    this.#removeCouponButtons = this.#htmlDOMElement.querySelectorAll(
      "[data-remove-coupon-code]",
    );

    this.#errorsWrapperDomElement = this.#htmlDOMElement.querySelector(
      "[data-coupon-code-errors]",
    );
  }

  initEvents() {
    if (this.#couponCodeForm && this.#couponCodeInput) {
      this.#couponCodeForm.addEventListener(
        "submit",
        this.onCouponCodeSubmit.bind(this),
      );
    }

    if (this.#removeCouponButtons) {
      this.#removeCouponButtons.forEach((button) => {
        button.addEventListener("click", this.onRemoveCouponCode.bind(this));
      });
    }
  }

  onRemoveCouponCode(event) {
    event.preventDefault();
    let code = event.target.dataset.removeCouponCode;
    this.#cartManager.removeCouponCode(code);
  }

  onCouponCodeSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("coupon_code", this.#couponCodeInput.value);
    this.#cartManager.applyCouponCode(formData);
  }

  destroy() {
    // Usuwanie nasłuchiwacza z formularza kuponu
    if (this.#couponCodeForm) {
      this.#couponCodeForm.removeEventListener(
        "submit",
        this.onCouponCodeSubmit.bind(this),
      );
    }

    // Usuwanie nasłuchiwaczy z przycisków usuwania kuponów
    if (this.#removeCouponButtons) {
      this.#removeCouponButtons.forEach((button) => {
        button.removeEventListener("click", this.onRemoveCouponCode.bind(this));
      });
    }

    // Resetowanie wszystkich prywatnych pól
    this.#htmlDOMElement = null;
    this.#cartManager = null;
    this.#couponCodeForm = null;
    this.#couponCodeInput = null;
    this.#removeCouponButtons = null;
  }
}
