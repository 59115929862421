/**
 * Mediator
 * @description
 * Mediator pattern to subscribe, unsubscribe and publish events
 */
export default class Mediator {
  static topics = {};

  /**
   * Subscribe
   * @param topic
   * @param fn
   */
  static subscribe(topic, fn) {
    if (!Mediator.topics[topic]) {
      Mediator.topics[topic] = [];
    }

    Mediator.topics[topic].push(fn);
  }

  /**
   * Unsubscribe
   * @param topic
   * @param fn
   */
  static unsubscribe(topic, fn) {
    if (!Mediator.topics[topic]) {
      return;
    }

    Mediator.topics[topic] = Mediator.topics[topic].filter((item) => {
      return item !== fn;
    });
  }

  /**
   * Publish
   * @param topic
   * @param data
   */
  static publish(topic, data) {
    if (!Mediator.topics[topic] || Mediator.topics[topic].length < 1) {
      return;
    }

    Mediator.topics[topic].forEach((fn) => {
      fn(data);
    });
  }
}
