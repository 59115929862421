import QuantityControl from "@scripts/components/QuantityControl.js";

export default class CartProduct {
  #key;
  #htmlDOMElement;
  #quantityControl;
  #removeButton;
  #cartManager;

  constructor(key, cartManager) {
    this.#key = key;
    this.#cartManager = cartManager;
    this.#htmlDOMElement = document.querySelector(
      `[data-sticky-cart-item="${this.#key}"]`,
    );

    if (!this.#htmlDOMElement) {
      return;
    }

    this.getHTMLDOMElements();
    this.initEvents();
  }

  get key() {
    return this.#key;
  }

  getHTMLDOMElements() {
    this.#quantityControl = new QuantityControl(
      this.#htmlDOMElement.querySelector("[data-quantity-control]"),
    );

    this.#quantityControl.subscribe(this.onQuantityChange.bind(this));
    this.#removeButton =
      this.#htmlDOMElement.querySelector("[data-remove-key]");
  }

  initEvents() {
    if (this.#removeButton) {
      this.#removeButton.addEventListener(
        "click",
        this.onRemoveButtonClick.bind(this),
      );
    }
  }

  onRemoveButtonClick() {
    this.#cartManager.removeFromCart(this.#removeButton, this.#key);
    this.hide();
  }

  onQuantityChange(state) {
    if (state?.value === 0) {
      this.#cartManager.removeFromCart(state.lastUsedButton, this.#key);
      this.hide();
    } else {
      this.#cartManager.updateQuantity(
        this.#key,
        state?.value,
        state?.lastUsedButton,
      );
    }
  }

  update(data, formattedData) {
    if (data.quantity && this.#quantityControl) {
      this.updateQuantity(data.quantity);
    }

    if (typeof formattedData === "object" && formattedData !== null) {
      this.updateFormattedData(formattedData);
    }
  }

  updateFormattedData(data) {
    // loop for all data object keys
    for (const key in data) {
      // if key is not quantity
      if (key !== "quantity") {
        // get element by key
        console.info("key", key);
        const element = this.#htmlDOMElement.querySelector(
          `[data-ajax-update="${key}"]`,
        );
        console.info("element", element);
        // if element exists
        if (element) {
          // update element value
          console.info("data[key] value", data[key]);
          element.innerHTML = "";
          element.insertAdjacentHTML("beforeend", data[key]);
        }
      }
    }
  }

  updateQuantity(quantity) {
    if (quantity === 0) {
      this.hide();
      return;
    }
    // update product quantity
    this.#quantityControl.setValue(quantity, true);
  }

  hide() {
    // animation to hide the product
    this.#htmlDOMElement.style.transition = "all 0.8s ease";
    this.#htmlDOMElement.style.opacity = 0;
    // translate to right
    this.#htmlDOMElement.style.transform = "translateX(100%)";

    setTimeout(() => {
      this.#htmlDOMElement.style.display = "none";
      this.destroy();
    }, 850);
  }

  destroy() {
    // destroy this object and remove all events and destroy quantity control
    this.#quantityControl.destroy();
    this.#removeButton.removeEventListener("click", this.onRemoveButtonClick);
    this.#htmlDOMElement.remove();
  }
}
