export default class QuantityControl {
  #element;
  #subscribers;
  #input;
  #decreaseButton;
  #increaseButton;
  #maxValue;

  constructor(element) {
    this.element = element;
    // check if element exists in the DOM
    if (!this.element) {
      return;
    }

    this.init();
  }

  init() {
    this.subscribers = [];
    this.input = this.element.querySelector("[data-quantity-control-input]");
    this.decreaseButton = this.element.querySelector(
      "[data-quantity-control-decrease]",
    );
    this.increaseButton = this.element.querySelector(
      "[data-quantity-control-increase]",
    );

    this.maxValue = this.input.getAttribute("max") || 999999;

    this.state = {
      value: parseInt(this.input.value),
      decreaseButton: this.decreaseButton,
      increaseButton: this.increaseButton,
      lastUsedButton: null,
    };

    this.addEventListeners();
  }

  addEventListeners() {
    this.decreaseButton.addEventListener(
      "click",
      this.decreaseQuantity.bind(this),
    );
    this.increaseButton.addEventListener(
      "click",
      this.increaseQuantity.bind(this),
    );

    this.input.addEventListener("change", () => {
      let value = parseInt(this.input.value);
      if (value > this.maxValue) {
        this.input.value = this.maxValue;
      }

      this.updateState({
        value: value,
        lastUsedButton: null,
      });
    });
  }

  decreaseQuantity() {
    let value = parseInt(this.input.value);
    if (value > 0) {
      value--;
      this.updateState({
        value: value,
        lastUsedButton: this.decreaseButton,
      });
    }
  }

  increaseQuantity() {
    let value = parseInt(this.input.value);
    if (value < this.maxValue) {
      value++;

      this.updateState({
        value: value,
        lastUsedButton: this.increaseButton,
      });
    }
  }

  updateState(newState, silent = false) {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.input.value = this.state.value;

    if (!silent) {
      this.notifySubscribers(this.state);
    }
  }

  subscribe(callback) {
    this.subscribers.push(callback);
  }

  notifySubscribers(state) {
    this.subscribers.forEach((callback) => {
      callback(state);
    });
  }

  setValue(value, silent = false) {
    this.updateState(
      {
        value: value,
        lastUsedButton: null,
      },
      silent,
    );

    this.input.value = value;
  }

  destroy() {
    this.decreaseButton.removeEventListener("click", this.decreaseQuantity);
    this.increaseButton.removeEventListener("click", this.increaseQuantity);
    this.input.removeEventListener("change", () => {
      let value = parseInt(this.input.value);
      if (value > this.maxValue) {
        this.input.value = this.maxValue;
      }

      this.updateState({
        value: value,
        lastUsedButton: null,
      });
    });
  }
}
