import Mediator from "@scripts/core/Mediator.js";
import Toastify from "toastify-js";

export default class AppAjaxHandler {
  init() {
    this.fetchAppData();

    Mediator.subscribe("refresh_get_sage_app_data", (data) => {
      this.fetchAppData();
    });
  }

  fetchAppData() {
    let formData = new FormData();
    formData.append("nonce", window?.app?.nonce);
    formData.append("action", "sage_get_app_data");

    fetch(window?.app?.ajaxUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        this.handleResponse(response);
      })
      .catch((error) => {
        console.error("Error:", error);
        Toastify({
          text: "Error loading app data",
          duration: 3000,
          gravity: "top",
          position: "right",
          backgroundColor: "#ff0000",
        }).showToast();
      });
  }

  handleResponse(response) {
    if (response.success) {
      Mediator.publish("ajax_sage_get_app_data", { data: response?.data });
    }
  }
}
