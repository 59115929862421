import Debugger from "@scripts/core/Debugger.js";
import DomManager from "@scripts/core/DomManager.js";
import Mediator from "@scripts/core/Mediator.js";
import Loader from "@scripts/core/Loader.js";
import Toastify from "toastify-js";

export default class CartManager {
  static instance = null;

  constructor() {
    if (CartManager.instance) {
      return CartManager.instance;
    }

    this.loader = new Loader();
    this.addToCartActionName = "sage_add_to_cart";
    this.removeFromCartActionName = "sage_remove_from_cart";
    this.updateQuantityActionName = "sage_update_cart_item";
    this.applyCouponCodeActionName = "sage_apply_coupon_code";
    this.removeCouponCodeActionName = "sage_remove_coupon_code";

    CartManager.instance = this;
  }

  static getInstance() {
    if (!CartManager.instance) {
      CartManager.instance = new CartManager();
    }
    return CartManager.instance;
  }

  async applyCouponCode(formData) {
    try {
      const response = await this.#sendRequest(
        this.applyCouponCodeActionName,
        formData,
      );
      this.#handleApplyCouponCodeResponse(response, false);
      return response;
    } catch (error) {
      console.error(error);
      this.#notifyUser({ text: "Error applying coupon code", type: "error" });
    }
  }

  async removeCouponCode(code) {
    const formData = new FormData();
    formData.append("coupon_code", code);

    try {
      const response = await this.#sendRequest(
        this.removeCouponCodeActionName,
        formData,
      );
      this.#handleApplyCouponCodeResponse(response, false);
      return response;
    } catch (error) {
      console.error(error);
      this.#notifyUser({ text: "Error removing coupon code", type: "error" });
    }
  }

  async addToCart(button, product_id = null) {
    if (!button) {
      return Promise.reject(new Error("Button is missing"));
    }

    if (!product_id) {
      product_id = button.dataset.ajaxAddToCart;
    }
    if (!product_id) {
      console.error("Product ID is missing");
      return Promise.reject(new Error("Product ID is missing"));
    }

    return this.sendAddToCartRequest(button, product_id);
  }

  async updateQuantity(key, quantity, button = null) {
    const formData = new FormData();
    formData.append("key", key);
    formData.append("quantity", quantity);

    if (button) {
      DomManager.blockElementForNewEvents(button);
    }

    try {
      const response = await this.#sendRequest(
        this.updateQuantityActionName,
        formData,
      );
      this.#handleUpdateQuantityResponse(response);
    } catch (error) {
      console.error(error);
      this.#notifyUser({ text: "Error updating quantity", type: "error" });
    } finally {
      if (button) {
        DomManager.unblockElementForNewEvents(button);
      }
    }
  }

  async sendAddToCartRequest(
    button,
    product_id,
    quantity = 1,
    customFormData = null,
  ) {
    let formData = null;
    if (customFormData) {
      formData = customFormData;
    } else {
      formData = new FormData();
      formData.append("product_id", product_id);
      formData.append("quantity", quantity);
    }

    DomManager.blockElementForNewEvents(button);
    this.loader.render(button, "button");

    try {
      const response = await this.#sendRequest(
        this.addToCartActionName,
        formData,
      );
      this.#handleAddToCartResponse(response);
      return response;
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      this.loader.remove(button);
      DomManager.unblockElementForNewEvents(button);
    }
  }

  async removeFromCart(button = null, key = null) {
    const formData = new FormData();

    if (key) {
      formData.append("key", key);
    } else {
      if (!button) {
        console.error("Button is missing");
        return;
      }

      if (!button.dataset.ajaxRemoveFromCartKey) {
        console.error("Key is missing");
        return;
      }

      formData.append("key", button.dataset.ajaxRemoveFromCartKey);
    }

    if (button) {
      DomManager.blockElementForNewEvents(button);
    }

    try {
      const response = await this.#sendRequest(
        this.removeFromCartActionName,
        formData,
      );
      this.#handleRemoveFromCartResponse(response);
      return response;
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      if (button) {
        DomManager.unblockElementForNewEvents(button);
      }
    }
  }

  async #sendRequest(action, formData) {
    if (!action) {
      console.error("Action is missing");
      throw new Error("Action is missing");
    }

    Debugger.debug(`Sending request to ${action} action`);

    formData.append("action", action);
    formData.append("nonce", window?.app?.nonce);

    const response = await fetch(window?.app?.ajaxUrl, {
      method: "POST",
      body: formData,
      credentials: "same-origin",
    });

    return response.json();
  }

  #handleAddToCartResponse(response) {
    if (response?.success) {
      Mediator.publish("ajax_sage_change_cart", response);
    } else {
      Mediator.publish("ajax_sage_change_cart_error", response);
    }
    this.#notifyUser(response?.data?.notice);
  }

  #handleRemoveFromCartResponse(response) {
    if (response?.success) {
      Mediator.publish("ajax_sage_change_cart", response);
    } else {
      Mediator.publish("ajax_sage_change_cart_error", response);
    }
    this.#notifyUser(response?.data?.notice);
  }

  #handleUpdateQuantityResponse(response) {
    if (response?.success) {
      Mediator.publish("ajax_sage_change_cart", response);
    } else {
      Mediator.publish("ajax_sage_change_cart_error", response);
    }
    this.#notifyUser(response?.data?.notice);
  }

  #handleApplyCouponCodeResponse(response, notify = true) {
    if (response?.success) {
      Mediator.publish("ajax_sage_change_cart_coupon_success", response);
    } else {
      Mediator.publish("ajax_sage_change_cart_coupon_error", response);
    }
    if (notify) {
      this.#notifyUser(response?.data?.notice);
    }
  }

  #notifyUser(config) {
    Debugger.debug("notice config", config, "warning");
    const toastOptions = config;
    if (!toastOptions) {
      console.error("Toast options are missing");
      return;
    }
    Toastify(toastOptions).showToast();
  }
}
