/**
 * Class representing a parallax animation for an HTML element.
 *
 * @example
 * // JavaScript
 * import ParallaxAnimateElement from './ParallaxAnimateElement.js';
 *
 * document.addEventListener('DOMContentLoaded', () => {
 *   const item = document.querySelector('.parallax-item');
 *   const parallaxElement = new ParallaxAnimateElement(item);
 *   parallaxElement.init();
 * });
 *
 * // HTML
 * <div class="parallax-item" data-js-multiplier="0.1" data-js-direction="90"></div>
 *
 * // The data-js-multiplier attribute sets the speed of the parallax effect. Default set to 0.1.
 * // The data-js-direction attribute sets the direction of the parallax effect in degrees. Default set to 270.
 */
export default class ParallaxAnimateElement {
  #item;
  #multiplier;
  #direction;
  #startScrollY;
  #currentOffset;

  /**
   * Creates an instance of ParallaxAnimateElement.
   * @param {HTMLElement} item - The HTML element to animate.
   */
  constructor(item) {
    this.#item = item;
    this.#multiplier =
      parseFloat(item.getAttribute("data-js-multiplier")) || 0.1;
    this.#direction = parseFloat(item.getAttribute("data-js-direction")) || 270;
    this.#startScrollY = null;
    this.#currentOffset = 0;
  }

  /**
   * Initializes the parallax animation by adding a scroll event listener.
   */
  init() {
    window.addEventListener("scroll", this.#onScroll.bind(this));
    this.#onScroll();
  }

  /**
   * Handles the scroll event, updating the element's position.
   * @private
   */
  #onScroll() {
    const rect = this.#item.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const scrollY = window.scrollY;

    if (rect.bottom >= 0 && rect.top <= viewportHeight) {
      if (this.#startScrollY === null) {
        this.#startScrollY = scrollY;
      }

      this.#currentOffset = (scrollY - this.#startScrollY) * this.#multiplier;
      const radians = (this.#direction * Math.PI) / 180;
      const x = this.#currentOffset * Math.cos(radians);
      const y = this.#currentOffset * Math.sin(radians);

      if (this.#direction % 90 === 0) {
        if (this.#direction % 180 === 0) {
          this.#item.style.transform = `translateX(${x}px)`;
        } else {
          this.#item.style.transform = `translateY(${y}px)`;
        }
      } else {
        this.#item.style.transform = `translate(${x}px, ${y}px)`;
      }
    } else {
      this.#startScrollY = null;
    }
  }
}
