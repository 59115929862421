import Mediator from "@scripts/core/Mediator.js";
import Debugger from "@scripts/core/Debugger.js";

export default class ShippingBar {
  #shippingBar;
  #dynamicElements;
  #previousDifference;

  constructor(element) {
    this.#shippingBar = element;
    this.#dynamicElements = [];
    this.#previousDifference = null;

    if (!this.#shippingBar) {
      return;
    }

    this.getDynamicElements();
    this.subscribe();

    Debugger.debug("init ShippingBar", this.#shippingBar);
  }

  subscribe() {
    Mediator.subscribe(
      "ajax_sage_get_app_data",
      this.handleCartChange.bind(this),
    );
    Mediator.subscribe(
      "ajax_sage_change_cart",
      this.handleCartChange.bind(this),
    );
    Mediator.subscribe(
      "ajax_sage_change_cart_error",
      this.handleCartChange.bind(this),
    );
  }

  handleCartChange(response) {
    const data =
      response?.data?.values?.shippingBar ?? response?.data?.shippingBar;

    if (!data) {
      return;
    }

    this.updateBar(data);
  }

  getDynamicElements() {
    if (!this.#shippingBar) {
      return;
    }

    const ajaxElements =
      this.#shippingBar.querySelectorAll("[data-ajax-update]");

    ajaxElements.forEach((element) => {
      let key = element.getAttribute("data-ajax-update-key");
      let valueType = element.getAttribute("data-ajax-update-value-type");
      let styleAttribute =
        element.getAttribute("data-ajax-update-attribute") ?? null;
      let unit = element.getAttribute("data-ajax-update-unit") ?? null;

      this.#dynamicElements.push({
        element,
        key,
        valueType,
        styleAttribute,
        unit,
      });
    });
  }

  updateBar(data) {
    const shippingBarData = data;

    Debugger.debug("updateBar", shippingBarData);

    if (!shippingBarData) {
      return;
    }

    Debugger.debug(
      "shippingBarData?.isFreeShippingAvailable",
      shippingBarData?.isFreeShippingAvailable,
    );
    let freeShipping = shippingBarData?.isFreeShippingAvailable;

    const currentDifference =
      shippingBarData?.raw?.differenceBetweenThresholdAndCartTotal;

    freeShipping = freeShipping === true;

    Debugger.debug("freeShipping available", freeShipping);

    if (freeShipping === true) {
      this.#shippingBar.classList.add("is-free-shipping");
      return;
    } else {
      this.#shippingBar.classList.remove("is-free-shipping");
    }

    // Check if the difference has decreased or increased
    if (this.#previousDifference !== null) {
      if (currentDifference < this.#previousDifference) {
        this.#shippingBar.classList.add("is-decreasing");
      } else if (currentDifference > this.#previousDifference) {
        this.#shippingBar.classList.add("is-increasing");
      }
    }

    this.#previousDifference = currentDifference;

    this.#dynamicElements.forEach((dynamicElement) => {
      const { element, key, valueType, styleAttribute, unit } = dynamicElement;

      if (shippingBarData[valueType] && shippingBarData[valueType][key]) {
        this.updateElement(
          element,
          shippingBarData[valueType][key],
          styleAttribute,
          unit,
        );
      }
    });

    setTimeout(() => {
      this.#shippingBar.classList.remove("is-decreasing");
      this.#shippingBar.classList.remove("is-increasing");
    }, 800);
  }

  updateElement(element, value, styleAttribute, unit) {
    if (!element) {
      return;
    }

    if (styleAttribute === null) {
      element.innerHTML = "";
      element.insertAdjacentHTML("beforeend", value);
    } else {
      this.animateStyleAttribute(element, styleAttribute, value, unit);
    }
  }

  animateStyleAttribute(element, styleAttribute, value, unit) {
    element.style[styleAttribute] = value + unit;
  }
}
