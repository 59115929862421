import Mediator from "@scripts/core/Mediator.js";
import ViewportManager from "@scripts/core/ViewportManager.js";
import Debugger from "@scripts/core/Debugger.js";

export default class MiniCartWidget {
  constructor(root) {
    this.root = root;
    this.counter = null;
  }

  init() {
    this.getDomElements();
    this.handleDomEvents();
    this.subscribe();
  }

  subscribe() {
    Mediator.subscribe(
      "ajax_sage_get_app_data",
      this.setCounterValue.bind(this),
    );
    Mediator.subscribe(
      "ajax_sage_change_cart",
      this.setCounterValue.bind(this),
    );
    Mediator.subscribe(
      "ajax_sage_change_cart_error",
      this.setCounterValue.bind(this),
    );
  }

  setCounterValue(response) {
    const cartData = response?.data?.cart ?? response?.data?.values?.cart;
    if (!cartData) {
      return;
    }
    this.counter.innerText = cartData?.itemCount;
  }

  getDomElements() {
    this.counter = this.root.querySelector('[data-widget-mini-cart="counter"]');
  }

  handleDomEvents() {
    // hover in /  hover on / esc
    this.root.addEventListener("click", (event) => {
      event.preventDefault();
      Mediator.publish("update_sticky_cart_view", "toggle");
    });

    // document.addEventListener("keydown", (event) => {
    //   Mediator.publish("update_sticky_cart_view", "forceClose");
    // });
  }
}
