import Mediator from "@scripts/core/Mediator.js";
import ShippingBar from "@scripts/components/sticky-cart/ShippingBar.js";
import CartProduct from "@scripts/components/sticky-cart/CartProduct.js";
import CartManager from "@scripts/components/cart/CartManager.js";
import CrossProduct from "@scripts/components/sticky-cart/CrossProduct.js";
import CouponCodeForm from "@scripts/components/sticky-cart/CouponCodeForm.js";
import Debugger from "@scripts/core/Debugger.js";
import Toastify from "toastify-js";
import ViewportManager from "@scripts/core/ViewportManager.js";

export default class StickyCart {
  #stickyCartDomElement;
  #cartManager;
  #shippingBar;
  #sectionsMap = {};
  #componentsMap = {};
  #loader;
  #state = {
    loading: true,
    visible: false,
    empty: true,
    cartSummaryIsVisible: false,
  };
  #closeButton;

  constructor() {
    this.#stickyCartDomElement = document.querySelector("[data-sticky-cart]");
    if (!this.#stickyCartDomElement) {
      console.error("Sticky cart element not found");
      return;
    }
    this.#loader = this.#stickyCartDomElement.querySelector(
      "[data-sticky-cart-loader]",
    );
    this.#cartManager = new CartManager();
  }

  init() {
    if (!this.#stickyCartDomElement) {
      return;
    }

    // Prevent to CLS (Cumulative Layout Shift) by setting display to block after styles are loaded
    this.#stickyCartDomElement.style.display = "flex";

    this.#closeButton = this.#stickyCartDomElement.querySelector(
      "[data-sticky-cart-close-button]",
    );
    if (this.#closeButton) {
      this.#closeButton.addEventListener("click", () => {
        this.forceClose();
      });
    }

    this.setSectionsMap(
      this.#stickyCartDomElement.querySelectorAll("[data-sticky-cart-section]"),
    );
    this.addEvents();

    this.subscribe();
  }

  addEvents() {
    this.#stickyCartDomElement.addEventListener(
      "scroll",
      this.onScroll.bind(this),
    );
  }

  onScroll() {
    this.checkSectionsVisibility();
  }

  checkSectionsVisibility(key = "cartSummary") {
    let stateName = key + "IsVisible";

    if (
      this.#sectionsMap[key] &&
      ViewportManager.isElementInViewport(this.#sectionsMap[key], 10)
    ) {
      this.#sectionsMap[key].classList.add("is-visible");
      // check stateNameExists in state object
      if (stateName in this.#state) {
        this.updateState({ [stateName]: true });
      }
    } else {
      this.#sectionsMap[key].classList.remove("is-visible");
      // check stateNameExists in state object
      if (stateName in this.#state) {
        this.updateState({ [stateName]: false });
      }
    }
  }

  setSectionsMap(elements) {
    elements.forEach((section) => {
      let key = section.getAttribute("data-sticky-cart-section");
      this.#sectionsMap[key] = section;
    });
  }

  toggleLoader() {
    if (this.#stickyCartDomElement) {
      this.#stickyCartDomElement.dataset.isLoading =
        this.#state.loading.toString();
    }

    this.initShippingBar();
  }

  subscribe() {
    Mediator.subscribe("update_sticky_cart_view", this.updateView.bind(this));

    let events = [
      "ajax_sage_get_app_data",
      "ajax_sage_change_cart",
      "ajax_sage_change_cart_error",
      "ajax_sage_change_cart_coupon_success",
      "ajax_sage_change_cart_coupon_error",
    ];

    events.forEach((event) => {
      Mediator.subscribe(event, this.handleCartChange.bind(this));
    });
  }

  updateView(method_name) {
    if (method_name === "toggle") {
      this.toggleView();
    } else if (method_name === "forceClose") {
      this.forceClose();
    } else {
      this.forceClose();
    }

    this.checkSectionsVisibility();
  }

  toggleView() {
    this.#state.visible = !this.#state.visible;
    this.#stickyCartDomElement.dataset.isVisible =
      this.#state.visible.toString();
  }

  forceClose() {
    this.#state.visible = false;
    this.#stickyCartDomElement.dataset.isVisible = "false";
  }

  handleCartChange(response) {
    const cartData = response?.data?.cart ?? response?.data?.values?.cart;
    if (!cartData) {
      return;
    }
    this.renderSections(cartData);
  }

  renderSections(cartData, filteredKeys = []) {
    if (!cartData?.html) {
      this.showToast("Error loading cart data", "error");
      return false;
    }

    Debugger.debug("Cart data", cartData);

    for (let [key, html] of Object.entries(cartData.html)) {
      if (filteredKeys.length && filteredKeys.includes(key)) continue;

      const section = this.#sectionsMap[key];
      if (section) {
        section.innerHTML = html;
      }
    }

    this.setSectionsMap(
      this.#stickyCartDomElement.querySelectorAll("[data-sticky-cart-section]"),
    );
    this.initDynamicComponents();
    this.updateState({ loading: false });

    if (cartData?.itemCount === 0) {
      this.updateState({ empty: true });
    } else {
      this.updateState({ empty: false });
    }

    return true;
  }

  updateState(newState = {}) {
    this.#state = { ...this.#state, ...newState };
    this.toggleLoader();
    this.toggleEmptyState();
    this.toggleCartSummaryVisibility();
  }

  initDynamicComponents() {
    this.initCartProducts();
    this.initCrossSellProducts();
    this.initCouponCodeForm();
  }

  initShippingBar() {
    const shippingBarElement = this.#stickyCartDomElement.querySelector(
      "[data-shipping-bar]",
    );
    if (shippingBarElement) {
      this.#shippingBar = new ShippingBar(shippingBarElement);
    }
  }

  initCartProducts() {
    const cartItemsElement = this.#stickyCartDomElement.querySelector(
      '[data-sticky-cart-section="cartItems"]',
    );

    if (cartItemsElement) {
      this.#componentsMap.cartProducts = Array.from(
        cartItemsElement.querySelectorAll("[data-sticky-cart-item]"),
      ).map((item) => {
        const key = item.getAttribute("data-sticky-cart-item");
        return new CartProduct(key, this.#cartManager);
      });
    }
  }

  initCrossSellProducts() {
    const crossSellsWrapper = this.#stickyCartDomElement.querySelector(
      '[data-sticky-cart-section="crossSells"]',
    );
    if (crossSellsWrapper) {
      this.#componentsMap.crossSellProducts = Array.from(
        crossSellsWrapper.querySelectorAll(
          "[data-sticky-cart-cross-sell-product]",
        ),
      ).map((item) => new CrossProduct(item, this.#cartManager));
    }
  }

  initCouponCodeForm() {
    const couponFormElement = this.#stickyCartDomElement.querySelector(
      '[data-sticky-cart-section="couponForm"]',
    );
    if (couponFormElement) {
      this.#componentsMap.couponCodeForm = new CouponCodeForm(
        couponFormElement,
        this.#cartManager,
      );
    }
  }

  destroyComponents() {
    for (let component of Object.values(this.#componentsMap)) {
      if (Array.isArray(component)) {
        component.forEach((item) => item.destroy && item.destroy());
      } else if (component && typeof component.destroy === "function") {
        component.destroy();
      }
    }
    this.#componentsMap = {};
  }

  showToast(message, type = "info") {
    Toastify({
      text: message,
      duration: 3000,
      close: true,
      gravity: "top",
      position: "center",
      backgroundColor:
        type === "error"
          ? "linear-gradient(to right, #ff5f6d, #ffc371)"
          : "linear-gradient(to right, #00b09b, #96c93d)",
    }).showToast();
  }

  toggleEmptyState() {
    if (this.#stickyCartDomElement) {
      this.#stickyCartDomElement.dataset.isEmpty = this.#state.empty.toString();
    }
  }

  toggleCartSummaryVisibility() {
    if (this.#stickyCartDomElement) {
      this.#stickyCartDomElement.dataset.summaryIsVisible =
        this.#state.cartSummaryIsVisible.toString();
    }
  }
}
